var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"new-client panel is-primary"},[_c('h1',{staticClass:"title is-6 panel-heading mb-0"},[_vm._v(" Создание нового клиента (нерезидент) ")]),_c('form',{staticClass:"box",on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('div',{staticClass:"columns is-multiline"},[_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Вид документа"}},[_c('b-radio-button',{attrs:{"native-value":"0","type":"is-primary","expanded":""},model:{value:(_vm.isPassport),callback:function ($$v) {_vm.isPassport=$$v},expression:"isPassport"}},[_c('b-icon',{attrs:{"icon":"id-card","size":"is-medium"}}),_c('span',[_vm._v("Вид на жительство")])],1),_c('b-radio-button',{attrs:{"native-value":"1","type":"is-primary","expanded":""},model:{value:(_vm.isPassport),callback:function ($$v) {_vm.isPassport=$$v},expression:"isPassport"}},[_c('b-icon',{attrs:{"icon":"passport","size":"is-medium"}}),_c('span',[_vm._v("Паспорт")])],1)],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Фамилия","type":{
            'is-danger': _vm.$v.lastName.$error || 'lastName' in _vm.serverValid,
            'is-success': !_vm.$v.lastName.$invalid && _vm.$v.lastName.$dirty,
          },"message":{ 'Некорректно указана фамилия': _vm.$v.lastName.$error }}},[_c('b-input',{attrs:{"placeholder":"Фамилия"},on:{"blur":function($event){_vm.onTouchField('lastName');
              _vm.removeKeyFromServerValid('lastName');}},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"lastName"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Имя","type":{
            'is-danger': _vm.$v.firstName.$error || 'firstName' in _vm.serverValid,
            'is-success': !_vm.$v.firstName.$invalid && _vm.$v.firstName.$dirty,
          },"message":{ 'Некорректно указано имя': _vm.$v.firstName.$error }}},[_c('b-input',{attrs:{"placeholder":"Имя"},on:{"blur":function($event){_vm.onTouchField('firstName');
              _vm.removeKeyFromServerValid('firstName');}},model:{value:(_vm.firstName),callback:function ($$v) {_vm.firstName=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"firstName"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Пол"}},[_c('b-radio-button',{attrs:{"native-value":"0","type":"is-primary","expanded":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('b-icon',{attrs:{"icon":"male","size":"is-medium"}}),_c('span',[_vm._v("Мужской")])],1),_c('b-radio-button',{attrs:{"native-value":"1","type":"is-primary","expanded":""},model:{value:(_vm.gender),callback:function ($$v) {_vm.gender=$$v},expression:"gender"}},[_c('b-icon',{attrs:{"icon":"female","size":"is-medium"}}),_c('span',[_vm._v("Женский")])],1)],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":_vm.textSeries,"type":{
            'is-danger':
              _vm.$v.passportSeries.$error || 'passportSeries' in _vm.serverValid,
            'is-success':
              !_vm.$v.passportSeries.$invalid && _vm.$v.passportSeries.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.passportSeries.$error }}},[_c('b-input',{attrs:{"placeholder":""},on:{"blur":function($event){_vm.onTouchField('passportSeries');
              _vm.removeKeyFromServerValid('passportSeries');}},model:{value:(_vm.passportSeries),callback:function ($$v) {_vm.passportSeries=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"passportSeries"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":_vm.textNumber,"type":{
            'is-danger':
              _vm.$v.passportNumber.$error || 'passportNumber' in _vm.serverValid,
            'is-success':
              !_vm.$v.passportNumber.$invalid && _vm.$v.passportNumber.$dirty,
          },"message":{ 'Некорректно заполено поле': _vm.$v.passportNumber.$error }}},[_c('b-input',{attrs:{"placeholder":""},on:{"blur":function($event){_vm.onTouchField('passportNumber');
              _vm.removeKeyFromServerValid('passportNumber');}},model:{value:(_vm.passportNumber),callback:function ($$v) {_vm.passportNumber=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"passportNumber"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Телефон","type":{
            'is-danger': _vm.$v.phone.$error || 'phone' in _vm.serverValid,
            'is-success': !_vm.$v.phone.$invalid && _vm.$v.phone.$dirty,
          },"message":{ 'Телефон в формате +7(900)000-00-00': _vm.$v.phone.$error }}},[_c('IMaskComponent',{staticClass:"input",class:{
              'is-danger': _vm.$v.phone.$error || 'phone' in _vm.serverValid,
              'is-success': !_vm.$v.phone.$invalid && _vm.$v.phone.$dirty,
            },attrs:{"placeholder":"+7(900)000-00-00","maxlength":"16","inputmode":"numeric","mask":'+{7}({9}00)000-00-00'},on:{"blur":function($event){_vm.onTouchField('phone');
              _vm.removeKeyFromServerValid('phone');}},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"phone"}})],1)],1),_c('div',{staticClass:"column is-full-mobile is-half-tablet is-one-third-desktop is-one-quarter-fullhd"},[_c('b-field',{attrs:{"label":"Email","type":{
            'is-danger': _vm.$v.email.$error || 'email' in _vm.serverValid,
            'is-success': !_vm.$v.email.$invalid && _vm.$v.email.$dirty,
          },"message":{ 'Некорректно указан email': _vm.$v.email.$error }}},[_c('b-input',{attrs:{"placeholder":"example@mail.com"},on:{"blur":function($event){_vm.onTouchField('email');
              _vm.removeKeyFromServerValid('email');}},model:{value:(_vm.email),callback:function ($$v) {_vm.email=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"email"}})],1)],1)]),_c('div',{staticClass:"button-wrapper"},[_c('b-button',{attrs:{"type":"is-info","native-type":"submit"}},[_vm._v("Создать")])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }